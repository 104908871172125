@font-face {
    font-family: "Roboto";
    src: url("fonts/Roboto-Regular.ttf") format("truetype");
}
@font-face {
    font-family: 'Rubik-Medium';
    src: url('fonts/Rubik-Medium.ttf');
}
@font-face {
    font-family: 'Rubik-Light';
    src: url('fonts/Rubik-Light.ttf');
}
@font-face {
    font-family: 'Rubik-Bold';
    src: url('fonts/Rubik-Bold.ttf');
}
@font-face {
    font-family: 'Rubik-Regular';
    src: url('fonts/Rubik-Regular.ttf');
}
@font-face {
    font-family: 'Rubik-Black';
    src: url('fonts/Rubik-Black.ttf');
}
@font-face {
    font-family: 'Inter-Medium';
    src: url('fonts/Inter-Medium.ttf');
}
@font-face {
    font-family: 'Inter-Bold';
    src: url('fonts/Inter-Bold.ttf');
}
@font-face {
    font-family: 'Inter-Light';
    src: url('fonts/Inter-Light.ttf');
}
body {
    font-family: "Roboto", sans-serif;
}

h1 {
    font-size: 1.25rem;
    font-weight: 400;
}
h2 {
    font-size: 1.0rem;;
    font-weight: 200;
}
h3 {
    font-size: 1.25rem;
    font-weight: 400;
}
h4 {
    font-size: 1.25rem;
}
h5 {
    font-size: 1.25rem;
}
p {
    font-size: 1rem;
}
a {
    font-size: 1rem;
}

@media (min-width: 480px) {
    h1 {
        font-size: 1.5rem;
        font-weight: 400;
    }
    h2 {
        font-size: 1.25rem;;
        font-weight: 200;
    }
}

@media (min-width: 768px) { 
    h1 {
        font-size: 1.75rem;
        font-weight: 400;
    }
    h2 {
        font-size: 1.75rem;;
        font-weight: 200;
    }
}