// Normalization
body,
h1,
h2,
h3 {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

// App styles
.k-drawer-container {
    background-color: $bg-grey;

    .k-drawer {
        box-shadow: 0 10px 10px rgba(0,0,0,.2);
        border-right-width: 0 !important;
    }

    &.k-drawer-push .k-drawer {
        position: sticky;
        top: 60px;
        height: calc(100vh - 60px);
    }

    .k-drawer-item {
        user-select: none;
    }
}

.k-drawer-content {
    overflow: scroll;
    background-color: $bg-grey;
}
.back-btn {
    background-color: $primary;
    color: white;
    border: none;
    text-decoration: none;
    margin: 10px;
    border-radius: 13px;
    font-family: Rubik-Regular;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
}
.not-found-btn {
    background-color: $primary;
    color: white;
    border: none;
    text-decoration: none;
    padding: 10px;
    border-radius: 3px;
    font-family: Inter-Bold;
    cursor: pointer;
}

.card-container {
    background-color: $white;
    padding: 40px 0;
    text-align: center;
    border-radius: 13px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);

    &.grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        column-gap: 10px;
        row-gap: 1em;
    }

    .card-title {

    }

    .card-buttons {
        .k-button {
            min-width: 105px;
        }
    }

    .card-ranges {
        .k-daterangepicker {
            .k-textbox-container, .k-floating-label-container {
                padding-top: 0; 
                min-width: 105px;
            }

            .k-label {
                display: none;
            }
        }
    }

    .card-component {
        grid-column: 1 / -1;
        overflow: hidden;
    }
}

@media (min-width: 480px) {
    .card-container {
        text-align: initial;
        padding: 30px;

        &.grid {
            grid-template-columns: repeat(2, 1fr);
        }

        .card-title {
            grid-column: 1 / 2;
            grid-row: 1;
        }

        .card-buttons {
            text-align: center;
            grid-column: 1 / -1;
            grid-row: 2;
        }

        .card-ranges {
            text-align: right;
            grid-column: 2 / 3;
            grid-row: 1;
        }
    }

 }

@media (min-width: 768px) { 
    .card-container {
        margin: 25px;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0,0,0,.2);

        &.grid {
            grid-template-columns: repeat(3, 1fr);
        }

        .card-title {

        }

        .card-buttons {
            text-align: center;
            grid-column: 2 / 3;
            grid-row: 1;
        }

        .card-ranges {
            text-align: right;
            grid-column: 3 / 4;
        }

        .card-component {
            grid-column-start: 1;
            grid-column-end: -1;
        }
    }
}

// Login Page
.login-page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #F5F6F6;
    height: 100vh;
    .card-container {
        width: 420px;
        height: 400px;
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media (max-width: 500px) { 
            width: 90%;
            padding: 10px;
          }
        .reset-text {
            font-family: Inter-Medium;
            color: $primary;
        }
        .card-component {
            .k-label {
                @media (max-width: 400px) { 
                    font-size: 12px;
                  }
            }
            .header-img-container {
                display: flex;
                justify-content: center;
                align-items: center;
                .cp-header-img {
                    width: 400px;
                    padding: 5px;
                    padding-bottom: 20px;
                    @media (max-width: 400px) { 
                       width: 300px;
                      }
                }
            }
            .login-header {
                margin-bottom: 15px;
                color: $primary;
                font-weight: 500;
                text-align: left;
            }
            .login-btn {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .forgot-pass-btn {
                width: 50%;
                margin-left: 25%;
                margin-top: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                .forgot-pass-inner {
                    background: none;
                    color: $primary;
                    border: none;
                    font-family: Inter-Medium;
                }
            }
            .code-back-btn {
                width: 25%;
                .forgot-pass-inner {
                    background: none;
                    color: $primary;
                    border: none;
                    font-family: Inter-Medium;
                    text-align: left;
                }
            }

        }
        .sign-up-block {
            margin-top: 50px;
            // width: 50%;
            // margin-left: 25%;
            display: flex;
            justify-content: center;
            align-items: center;
            .sign-up-text {
                padding-bottom: 0;
                margin-bottom: 4px;
                color: $primary;
            }
            .sign-up-btn-container {
                display: flex;
                justify-content: center;
                align-items: center;

            }
            .sign-up-btn {
                background: none;
                border: none;
                color: $primary;
                text-decoration: underline;
                font-family: Inter-Medium;
                @media (max-width: 400px) { 
                   font-size: 12px;
                 }
            }
        }
        .customer_login_txt {
            color: $primary;
            font-family: Inter-Bold;
            margin-top: 0;
            padding-top: 0;
        }
    }
    .login-waves {
        width: 100%;
    }
    .code_screen_btn_container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}

.forgot-pass-page {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    background-color: $bg-grey;
    height: 100vh;
    
    .card-container {
        width: 90%;
        border-radius: 13px;
        margin-top: 100px;
        .header-img-container {
            display: flex;
            justify-content: center;
            align-items: center;
            .cp-header-img {
                width: 400px;
                padding: 5px;
                padding-bottom: 20px;
                @media (max-width: 400px) { 
                   width: 300px;
                  }
            }
        }
        .reset-header {
            font-family: Rubik-Medium;
            color: $primary;
        }
        .reset-text {
            font-family: Rubik-Regular;
            color: $primary;
        }
        .reset-pass-btn {
            font-family: Rubik-Regular;
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
            border-radius: 13px;
            margin: 10px;
            border: none;
            width: 150px;
            background-color: $primary;
        }
    }
}

.signup-page {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    background-color: $bg-grey;
    height: 100vh;

    .card-container {
        margin-top: 100px;
        border-radius: 13px;
        width: 420px;
        @media (max-width: 500px) { 
            margin-top: 10px;
            width: 90%;
            padding: 10px;
          }
        .reset-header {
            font-family: Rubik-Medium;
            color: $primary;
        }
        .reset-text {
            font-family: Rubik-Regular;
            color: $primary;
        }
        width: 90%;
        .header-img-container {
            display: flex;
            justify-content: center;
            align-items: center;
            .cp-header-img {
                width: 225px;
                padding: 10px;
                padding-bottom: 20px;
            }
        }
        .signup-btn {
            font-family: Rubik-Regular;
            background-color: $primary;
            border-radius: 13px;
            border: none;
            width: 150px;
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
            margin: 10px;
        }
    }
}


// Header
.header {
    height: 60px;
    @media (max-width: 400px) { 
       height: 100px;
      }
    position: sticky;
    top: 0;
    z-index: 3;
    background-color: white;
    .header-buttons {
        @media (max-width: 400px) { 
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .buy-sensor-btn {
                font-family: Rubik-Regular;
                box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
                color: black;
                background-color: $bg-grey;
                margin-right: 15px;
                margin-bottom: 5px;
                border-radius: 13px;
                width: 150px;
            }
            .manage-sub-btn {
                font-family: Rubik-Regular;
                box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
                color: white;
                background-color: $primary;
                margin-right: 15px;
                border-radius: 13px;
                width: 200px;
            }
          }
    }
    .dropdown {
        min-width: 200px;
        font-family: Rubik-Medium;
        .k-dropdown-wrap {
            border: none; 
        }
        .k-select {
            background-color: #FFFFFF;
        }
        .k-input {
            font-family: Rubik-Medium;
            color: $primary-dark;
            background-color: white;
            .k-i-arrow-s::before {
                color: $primary-dark;
            }
        }
    }
    .buy-sensor-btn {
        font-family: Rubik-Regular;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
        color: black;
        background-color: $bg-grey;
        margin-right: 15px;
        border-radius: 13px;
        width: 150px;
    }
    .manage-sub-btn {
        font-family: Rubik-Regular;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
        color: white;
        background-color: $primary;
        margin-right: 15px;
        border-radius: 13px;
        width: 200px;
    }

    .k-i-menu {
        color: $primary;
    }

    .nav-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        color: $white;
    }

    .menu-button {
        flex: 0 0 50px;
        text-align: center;
    }

    .hamburger-icon {
        background-image:  url('./../assets/hamburger-icon.svg');
        background-repeat: no-repeat;
        width: 20px;
        cursor: pointer;
    }

    .title {
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 25px;
        img {

        }
        h1 {
            font-family: Inter-Bold;
            margin-left: 10px;
            color: white;
        }
    }

    .k-avatar {
        display: block;
        width: 40px;
        height: 40px;
        flex-basis: 40px;
        margin: 0 25px;
        box-shadow: 0 0 0px 1px $white;
    }

    .settings {
        display: none;
    }
}

@media (min-width: 480px) {
    .header {
        .title {
            flex-direction: row;
            align-items: center;
        }
        .vl {
            border-left: 1px solid $white;
            height: 25px;
            margin: 6px 15px;
        }
    }
}

@media (max-width: 480px) {
    .header {
        .title {
            flex-direction: row;
            align-items: center;
        }
        .vl {
            border-left: 1px solid $white;
            height: 25px;
            margin: 6px 15px;
        }
    }
}

@media (min-width: 768px) {
    .header {
        .settings {
            display: flex;
            flex: 0 0 auto;
            align-items: center;
            padding-right: 25px;
        }
        
        .k-dropdown {
            max-width: 90px;
            margin-left: 15px;
        }
    }
}

#blockColorblindContent {
    display: none;
}

// Locations Page
.dashboard-page {
    .card-container {
        background-color: $bg-grey;
        overflow: scroll;
        .location-card-body {
            border: 2px solid $primary-dark;
            border-radius: 13px;
            background-color: white;
            padding: 10px;
            width: 33%;
            margin: 20px;
            cursor: pointer;
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
            .card-header {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid black;
                .location-name {
                    font-family: Rubik-Medium;
                    color: $primary-dark;
                    text-align: left;
                    width: 70%;
                }
                h3 {
                    width: 30%;
                    text-align: right;
                }
            }
            .last-activity-text {
                // margin-top: hp('1%');
                font-family: Rubik-Regular;
                color: $primary-dark;
            }
        }
        .card-title {
            color: $primary;
            font-family: Rubik-Medium;
            width: 200%;
        }
        .card-component {
            .location-text {
                font-family: Rubik-Regular;
                color: $primary-dark;
                width: 90%;
                margin-left: 5%;
            }
            .location-cards-wrapper {
                display: flex;
                justify-content: space-evenly; 
                flex-wrap: wrap;
            }
            @media (max-width: 1000px) {
                .location-cards-wrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .location-card-body {
                        width: 80%;
                        max-width: 400px;
                    }
                }
            }
        }
        @media (max-width: 468px) {
            .card-title {
                width: 100%;
                padding: 5px;
            } 
            
        }
    }
}

// Dashboard page
// .dashboard-page {
//     .card-container:last-of-type {
//         padding-top: 0;
//         // height: 100%;
//     }

//     .k-grid-toolbar .k-textbox {
//         margin-bottom: 10px;
//     }

//     .k-grid .k-virtual-content,
//     .k-grid .k-virtual-content > div {
//         -ms-overflow-style: none;  /* IE and Edge */
//         scrollbar-width: none;  /* Firefox */
//         &::-webkit-scrollbar {
//             display: none;
//         }
//     }
    
//     .k-badge {
//         z-index: 1
//     }
// }

// @media (min-width: 480px) {
//     .dashboard-page {
//         .k-grid-toolbar .k-textbox {
//             margin-bottom: 0;
//             float: left;
//         }
    
//         .k-grid-toolbar .k-button {
//             float: right;
//         }
//     }
// }

// @media (min-width: 768px) {
//     .dashboard-page {
//         .card-container:last-of-type {
//             padding-top: 30px;
//         }
//     }
// }

// Planning page 
// .planning-page {
//     .card-container {
//         grid-template-columns: repeat(1, 1fr);
//     }
//     .card-title{
//         grid-column: 1 / -1;
//     }
//     .k-hbox {
//         padding: 0 20px;
//         background: transparent;
//         align-items: center;
//     }
//     .k-card-title {
//         font-size: 15px;
//         margin-bottom: -6px;
//         text-align: left;
//     }
//     .k-card-subtitle {
//         margin-top: 0;
//         text-transform: uppercase;
//         font-size: 11px;
//     }
//     .disabled {
//         opacity: 0.5;
//     }
// }

// @media (min-width: 480px) {
//     .planning-page {
//         .card-container {
//             grid-template-columns: repeat(2, 1fr);
//         }
//     }
// }

// @media (min-width: 768px) {
//     .planning-page {
//         .k-hbox {
//             padding: 0;
//             border-width: 0;
//         }
//     }
// }

// @media (min-width: 960px) {
//     .planning-page {
//         .card-container {
//             grid-template-columns: repeat(3, 1fr);
//         }
//     }
// }

// @media (min-width: 1360px) {
//     .planning-page {
//         .card-container {
//             grid-template-columns: repeat(5, 1fr);
//         }
//     }
// }

// Profile page
.profile-page {
    .settings {
        margin-left: 25px;
        margin-top: 15px;
        @media (min-width: 768px) {
            display: none;
            
        }
        @media (max-width: 480px) {
            margin-left: 5px;
            padding: 5px;
        }
    }
    .dropdown {
        min-width: 200px;
        .k-input {
            font-family: Inter-Medium;
            color: $primary-dark;
            background-color: white;
            .k-i-arrow-s::before {
                color: $primary-dark;
            }
        }
        .k-select {
            background-color: white;
        }
    }
    .buy-sensor-btn {
        font-family: Inter-Medium;
        color: white;
        background-color: $tiger-team;
    }

    .k-i-menu {
        color: $tiger-team;
    }
    .card-container {
        text-align: left;
        @media (max-width: 400px) { 
            width: 90%;
            margin-left: 1%;
          }
        .subscription-status {
            font-family: Rubik-Regular;
            color: $primary-dark;
            display: flex;
            flex-direction: row;
            .sub-status-inner {
                margin: 0;
                margin-left: 5px;
                padding: 0;
                text-decoration: underline;
            }
        }
        .save-changes-btn {
            width: 200px;
            height: 35px;
            padding: 5px;
            background-color: $primary;
            color: white;
            border: none;
            border-radius: 13px;
            // box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
            box-shadow: 0px 2px 2px grey;
            margin-top: 10px;
            margin-bottom: 5px;
            padding: 5px;
            font-family: Rubik-Medium;
        }
        .manage-sub-btn-profile {
            width: 200px;
            height: 35px;
            padding: 5px;
            background-color: $primary;
            color: white;
            border: none;
            border-radius: 13px;
            // box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
            box-shadow: 0px 2px 2px grey;
            margin-top: 10px;
            margin-bottom: 5px;
            padding: 5px;
            font-family: Rubik-Medium;
            margin-right: 10px;
        }
        .deactivate-btn {
            width: 200px;
            height: 35px;
            padding: 5px;
            // background-color: #EF3D3F;
            background-color: $bg-grey;
            color: black;
            border: none;
            border-radius: 13px;
            // box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
            box-shadow: 0px 2px 2px grey;
            margin-top: 10px;
            margin-bottom: 5px;
            padding: 5px;
            font-family: Rubik-Medium;
            margin-right: 10px;

        }
        .downgrade-btn {
            width: 200px;
            height: 35px;
            padding: 5px;
            // background-color: #EF3D3F;
            background-color: $bg-grey;
            color: black;
            border: none;
            border-radius: 13px;
            // box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
            box-shadow: 0px 2px 2px grey;
            margin-top: 10px;
            margin-bottom: 5px;
            padding: 5px;
            font-family: Rubik-Medium;
            margin-right: 10px;

        }
        .set-new-pass-btn {
            width: 200px;
            height: 35px;
            padding: 5px;
            background-color: $primary;
            color: white;
            border: none;
            border-radius: 13px;
            box-shadow: 0px 2px 2px grey;
            margin-top: 10px;
            margin-bottom: 5px;
            margin-left: 15px;
            padding: 5px;
            font-family: Rubik-Medium;
        }
        .account-title {
            font-family: Rubik-Medium;
            color: $primary;
            margin-bottom: 15px;;
        }
        .k-form {
            text-align: left;
            // border: 2px solid papayawhip;
            margin: 0;
            margin-top: 5px;
            // padding: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            .k-label {
                text-align: left;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
            }
            .k-form-field {
                // border: 2px solid plum;
                width: 60%;
                display: flex;
                // flex-direction: column;
                // align-items: center;
                // justify-content: center;
            }
        }
        .cg-unavailable-container {
            margin-top: 15px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            h3 {
                font-family: Rubik-Medium;
                color: $primary-dark;
                margin-bottom: 4px;
                padding-bottom: 0;
            }
            p, a {
                font-family: Rubik-Regular;
                margin-top: 10px;
                margin-bottom: 4px;
                text-decoration: none;
            }
            a {
                color: $primary;
                text-decoration: underline;
            }
        }
    }

    .k-form .k-form-field {
        display: block;
        padding: 0 15px;
    }

    .k-form-field:first-of-type .k-form-field-wrap {
        margin: auto;
    }

    .k-form-field:first-of-type .k-avatar {
        margin: 0 auto 1rem;
    }

    .k-form.k-form-horizontal .k-label {
        // width: 100%;
    }

    hr {
        margin-top: 1rem;
        opacity: 0.2;
    }

    .k-form .k-form-buttons {
        justify-content: center;

        & > * {
            min-width: 140px;
        }
    }
    // Caregivers
    .caregiver-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .caregiver-title {
        font-family: Rubik-Medium;
        color: $primary;
    }
    .caregiver-card-container {
        border-bottom: 1px solid $primary-dark;
        width: 50%;
        // border-radius: 5px;
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 480px) {
            width: 90%;
        }
        .caregiver-text {
            font-family: Rubik-Regular;
            color: $primary-dark;
        }
        .caregiver-actions {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            width: 33%;
            .remove-cg-btn {
                background: none;
                border: none;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-family: Rubik-Regular;
            }
            .remove-caregiver-text {
                color: $tiger-team;
                text-align: center;
                font-family: Rubik-Regular;
            }
            .copy-cg-btn {
                background: none;
                border: none;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
            .copy-caregiver-text {
                color: $primary;
                text-align: center;
                font-family: Rubik-Regular;
            }
        }
        .expired-text {
            color: $tiger-team;
            font-size: 12px;
            font-family: Rubik-Regular;
        }
    }
    .invite-cg-btn {
        background-color: $primary;
        border-radius: 13px;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
        color: white;
        border: none;
        text-decoration: none;
        width: 250px;
    }
    .do-invite-cg-btn {
        background-color: $primary;
        color: white;
        border: none;
        text-decoration: none;
        font-family: Rubik-Medium;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
        border-radius: 13px;
        margin-bottom: 5px;
    }
    .invite-container {
        border-bottom: 2px solid $primary;

        .invite-text {
            color: $primary;
            font-size: 13px;
            margin-bottom: 2px;
            margin-top: 6px;
            font-family: Rubik-Regular;
        }

    }
    .copied-msg {
        color: $info;
    }
    .invites-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 2px solid $primary;
        padding-bottom: 4px;
        margin-top: 40px;
    }
    .change-pass-btn {
        background-color: $bg-grey;
        color: black;
        border: 2px solid $bg-grey;
        font-family: Rubik-Medium;
        border-radius: 13px;
        text-decoration: none;
        margin-top: 10px;
        width: 200px;
        height: 35px;
        padding: 5px;
        box-shadow: 0px 2px 2px grey;
    }
}
// Phone Input
.PhoneInput {
    @media (max-width: 480px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        .PhoneInputInput {
            margin-top: 5px;
            width: 100%;
        }
    }
    .PhoneInputCountry {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .PhoneInputCountrySelect {
        width: auto;
        font-family: Rubik-Medium;
    }
    .PhoneInputInput {
        width: 50%;
        font-family: Rubik-Medium;
    }
    .PhoneInputCountryIcon {
        height: 20px;
        width: 20px;
        margin-left: 5px;
        margin-right: 5px;
    }
    display: flex;
    flex-direction: row;
    padding-bottom: 8px;
    padding-top: 8px;

}

// @media (min-width: 480px) {
//     .profile-page {
//         .k-form .k-form-field {
//             display: flex;
//         }

//         .k-form-field:first-of-type .k-avatar {
//             margin: 0;
//         }

//         .k-form.k-form-horizontal .k-label {
//             width: 25%;
//         }
//     }
// }

 @media (max-width: 768px) { 
    .profile-page {
        .card-container {
            margin-top: 10px;
            margin-bottom: 10px;
            border-radius: 5px;
            box-shadow: 0 0 10px rgba(0,0,0,.2);
            padding: 15px;
            .k-textbox {
                // border: 2px solid red;
                width: 150px;
                margin-left: 30px;
            }
            .k-label {
                // border: 2px solid green;
                // margin-right: 10px;
            }
        }
        // .k-form {
        //     margin: 0 auto;
        //     padding: 0;
        // }
        // .k-form .k-form-field {
        //     padding: 0;
        // }
    }
 }

// Devices page
.devices-page {
    .card-title {
        margin: 15px;
        margin-left: 0;
        color: $primary-dark;
        font-family: Rubik-Medium;
    }
    .card-container {
        background-color: $bg-grey;
        @media (max-width: 768px) {
            margin: 10px;
            border-radius: 13px;
            box-shadow: 0 0 10px rgba(0,0,0,.2);
            padding: 10px;
            .sensor-card-container {
                width: 80%;
            }
            .sensor-detail-text {
                text-align: left;
            }
        }
        padding-top: 5px;
        // height: 100%;
        .back-btn {
            background-color: $primary;
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
            color: white;
            border: none;
            text-decoration: none;
            margin-top: 5px;
            border-radius: 13px;
            font-family: Rubik-Regular;
        }
    }

    .sensor-card-container {
        border: 3px solid $primary;
        border-radius: 13px;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
        padding: 10px;
        background-color: white;
        width: 35%;
        .sensor-card-header {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            .k-i-circle {
                font-size: 30px;
            }
            .sensor-name {
                font-family: Rubik-Medium;
            }
        }
        .sensor-subheader {
            font-family: Rubik-Medium;
            color: $primary;
            margin-bottom: 4px;
            margin-top: 4px;
            padding-left: 8px;
        }
        .sensor-detail-text {
            font-family: Rubik-Regular;
            margin-top: 0;
            margin-bottom: 2px;
            padding-left: 8px;
        }
    }
}

// Info page
// .info-page {
//     background-color: $info-background;
//     color: $white;

//     h1, h2, h3, .component-link {
//         font-family: Metric,Helvetica,Arial,sans-serif;
//     }
//     a:not(.k-button) {
//         color: $link-color;
//     }
    
//     .content {
//         padding-top: 40px;
//         margin: 0 auto;
//         position: relative;
//         max-width: 820px;
//     }

//     .kendoka {
//         text-align: center;
//         img {
//             max-width: 280px;
//         }
//     }

//     .section-1 {
//         text-align: center;
//         h1 {
//             font-size: 48px;
//             font-weight: bold;
//             color: $react-color;
//         }

//         h2 {
//             font-size: 30px;
//             margin-bottom: 53px;
//         }

//         .button-group {
//             margin-bottom: 30px;

//             .k-button {
//                 display: block;
//                 margin: 10px auto;
//                 min-width: 145px;
//                 width: 60%;
//             }
//         }

//         .github-link {
//             display: inline-flex;
//             flex-direction: row;
//             align-items: center;
//             align-content: center;

//             .github-text {
//                 margin-left: 15px;
//             }
//         }
//     }

//     .section-2 {
//         text-align: center;
//         margin-top: 40px;
//         padding: 0 15px;
//     }

//     .section-3 {
//         -webkit-columns: 2;
//         column-count: 2;
//         margin-top: 40px;
//         padding: 0 15px;

//         ul {
//             list-style: none;
//             padding: 0;
//             margin: 0;
//         }
//     }

//     .package-item {
//         display: inline-block;
//         margin-top: 20px;
//     }

//     .package-title a {
//         font-size: 17px;
//         color: #CCCCCC;
//         text-transform: uppercase;
//     }

//     .component-link {
//         padding: 5px 0;
        
//         a {
//             font-size: 20px;
//         }
//     }

//     .footer {
//         background-image: url('../assets/info-background.svg');
//         background-repeat: no-repeat;
//         height: 320px;
//     }
// }

// @media (min-width: 480px) { 
//     .info-page {
//         .content {
//             padding: 40px 40px 0;
//         }
//         .kendoka {
//             img {
//                 max-width: 348px;
//             }
//         }

//         .section-1 {
//             h1 {
//                 font-size: 71px;
//             }

//             h2 {
//                 font-size: 38px;
//             }

//             .button-group {
//                 .k-button {
//                     display: inline-block;
//                     margin-right: 10px;
//                     width: auto;
//                 }
//             }
//         }
//         .section-3 {
//             -webkit-columns: 3;
//             column-count: 3;
//         }
//     }
// }
// @media (max-width: 480px) {
//     .k-form-horizontal .k-form-field-wrap {
//         max-width: 100%;
//     }
    
// }

// @media (min-width: 768px) { 
//     .info-page {
//         .content {
//             padding: 120px 40px 0;
//         }
//         .kendoka {
//             position: absolute;
//             right: 0;
//             top: 2em;
//             text-align: left;
//         }
//         .section-1,
//         .section-2 {
//             text-align: left;
//             padding: 0;
//         }
//         .section-3 {
//             -webkit-columns: 4;
//             column-count: 4;
//             padding: 0;
//         }
//         .footer {
//             background-size: cover;
//         }
//     }
// }

.k-drawer-item.k-state-selected {
    color: #4374B4;
    background-color: #4374B4;
}
.k-drawer-item.k-state-selected:hover {
    color: #4374B4;
    background-color: #4374B4;
}
.k-drawer-item:hover {
    color: #4374B4;
    background-color: #4374B4;
}

.k-item-text {
    font-family: Rubik-Regular;
}

.k-window-titlebar {
    background-color: $primary;
}

.dialogue-cancel-btn {
    font-family: Rubik-Regular;
    border-radius: 13px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
}

.dialogue-confirm-btn {
    font-family: Rubik-Regular;
    border-radius: 13px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    background-color: $primary;
    color: white;
}

.dialogue-confirm-btn:hover {
    font-family: Rubik-Regular;
    border-radius: 13px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    background-color: $tiger-team;
    color: white;
}

.k-button:hover {
    transform: scale(1.05) !important;
}

.email_link {
    color: $primary;
    text-decoration: none;
}

.k-textbox {
    background-color: $bg-grey;
}